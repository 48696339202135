<template>
    <section class="forms">
        <div class="page-header">
            <h3 class="page-title">
                <button type="button" class="btn btn-outline-success btn-sm mr-2" @click="goBack()">
                    <i class="mdi mdi-keyboard-backspace"></i></button>
                Edit Lesson of {{ module_name }} ({{ form_type }})
            </h3>

            <!-- <div class="button-group"> -->
                <!-- <button type="button" class="btn btn-info" @click="goBack()">
                    <i class="mdi mdi-keyboard-backspace"></i> Back to Content Listing</button> -->

                <!-- <b-dropdown class="" right variant="primary">
                    <template slot="button-content">
                        Add Content
                    </template>
                    <b-dropdown-item @click="showAddModal('audio')">Audio</b-dropdown-item>
                    <b-dropdown-item @click="showAddModal('video')">Video</b-dropdown-item>
                    <b-dropdown-item @click="showAddModal('pdf')">PDF</b-dropdown-item>
                    <b-dropdown-item @click="showAddModal('text')">Text</b-dropdown-item>
                </b-dropdown> -->
            <!-- </div> -->



            <!-- <router-link class="btn btn-primary believe-btn" :to="`/hypnosis/list/${current_category_id}`">
            <i class="mdi mdi-keyboard-backspace"></i> Back</router-link> -->
            <!-- <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0);">hypn</a></li>
          <li class="breadcrumb-item active" aria-current="page">Basic</li>
        </ol>
      </nav> -->
        </div>
        <div class="row">
            <div class="col-md-12 d-flex align-items-stretch grid-margin">
                <div class="row flex-grow">
                    <div class="col-8 m-auto grid-margin">
                        <div class="card">
                            <div class="card-body">
                                <!-- <h4 class="card-title">Default form</h4>
                                <p class="card-description">
                                    Basic form layout
                                </p> -->
                                <b-form @submit.stop.prevent="onSubmit">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <b-form-group id="example-input-group-1" label="Title"
                                                label-for="example-input-1">
                                                <b-form-input id="example-input-1" name="example-input-1"
                                                    v-model="name" aria-describedby="input-1-live-feedback">
                                                </b-form-input>
                                                <p class="err" v-if="name_err != ''">{{ name_err }}</p>
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-12">

                                            <b-form-group v-if="this.form_type == 'pdf' || this.form_type == 'text'"
                                                id="example-input-group-1" label="Author" label-for="example-input-1">
                                                <b-form-input id="example-input-1" name="example-input-1"
                                                    v-model="author" aria-describedby="input-1-live-feedback">
                                                </b-form-input>
                                                <p class="err" v-if="author_err != ''">{{ author_err }}</p>
                                            </b-form-group>

                                            <b-form-group v-else id="example-input-group-1" label="Artist"
                                                label-for="example-input-1">
                                                <b-form-input id="example-input-1" name="example-input-1"
                                                    v-model="artist" aria-describedby="input-1-live-feedback">
                                                </b-form-input>
                                                <p class="err" v-if="artist_err != ''">{{ artist_err }}</p>
                                            </b-form-group>
                                        </div>
                                    </div>


                                    <!-- <b-form-group v-if="this.form_type == 'pdf' || this.form_type == 'text'"
                                        id="example-input-group-1" label="Duration" label-for="example-input-1">
                                        <b-form-input id="example-input-1" name="example-input-1"
                                            v-model="file_duration" aria-describedby="input-1-live-feedback">
                                        </b-form-input>
                                        <p class="err" v-if="file_duration_err != ''">{{ file_duration_err }}</p>
                                    </b-form-group> -->



                                    <div class="container-fluid" v-if="this.form_type == 'audio' || this.form_type == 'pdf'">
                                        <div class="row">

                                            <!-- <div class="col-12" v-if="this.form_type == 'audio'">
                                                <div class="file-box row"  @click="$refs.audio_image.click()">
                                                    <div class="col-md-1">
                                                        <i class="mdi mdi-cloud-upload" style="font-size:50px"></i>
                                                        <input class="ml" type="file" 
                                                            @change="onAudioImageChange" ref="audio_image"
                                                            style="display: none" />
                                                    </div>
                                                    <div class="col-md-8">
                                                        <div class="file-text-box">
                                                            <span>Upload</span><br />
                                                            <small>Upload play screen image here</small>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <img v-if="audio_image_url !== ''" :src="audio_image_url"
                                                            class="img-thumbnail rounded-circle" alt="Image"
                                                            style="height: 65px; width: 65px; margin-top:8px" />
                                                    </div>
                                                </div>
                                                <p class="err" v-if="audio_image_url_err != ''">{{ audio_image_url_err
                                                }}</p>
                                            </div> -->
                                            <div class="col-12 my-4" v-if="this.form_type == 'audio' || this.form_type == 'pdf'">
                                                <div class="file-box row" @click="$refs.audio_image2.click()">
                                                    <div class="col-md-1">
                                                        <i class="mdi mdi-cloud-upload" style="font-size:50px"></i>
                                                        <input class="ml" type="file" 
                                                            @change="onAudioImageChange2" ref="audio_image2"
                                                            style="display: none" />
                                                    </div>
                                                    <div class="col-md-8">
                                                        <div class="file-text-box">
                                                            <span>Upload</span><br />
                                                            <small>Upload image here</small>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <img v-if="audio_image_url2 !== ''" :src="audio_image_url2"
                                                            class="img-thumbnail rounded-circle" alt="Image"
                                                            style="height: 65px; width: 65px; margin-top:8px" />
                                                    </div>
                                                </div>
                                                <p class="err" v-if="audio_cover_image_url_err != ''">{{
                                                        audio_cover_image_url_err
                                                }}</p>
                                            </div>

                                            <div class="col-12 mb-4" v-if="this.form_type == 'audio'">
                                                <div class="file-box row" v-if="audioFile">
                                                    <div class="col-md-12">
                                                        <div class="row file-text-box">
                                                            <div class="col-md-9">
                                                                <AudioFile :key="count" :audioSource="audioFile" />
                                                            </div>
                                                            <div class="col-md-3">
                                                                <button style="margin-top:6px;"
                                                                    @click="closeAudioPlayer()"
                                                                    class="btn btn-dark">x</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-else class="file-box row" @click="$refs.audioFile.click()">
                                                    <div class="col-md-1">
                                                        <i class="mdi mdi-cloud-upload" style="font-size:50px"></i>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <div class="file-text-box">
                                                            <span>Upload</span><br />
                                                            <small>Upload audio file here</small>
                                                        </div>
                                                    </div>
                                                </div>
                                                <input class="ml" type="file" 
                                                    @change="uploadAudio" ref="audioFile" style="display: none" />
                                                <p class="err" v-if="audioFile_err != ''">{{ audioFile_err }}</p>
                                            </div>

                                        </div>
                                    </div>


                                    <!-- <b-form-group v-if="this.form_type == 'pdf'" id="example-input-group-1"
                                        label="Current file" label-for="example-input-1">
                                        <a :href="this.pdf_file_old" target="_blank">{{ pdf_file_old }}</a>
                                    </b-form-group> -->

                                    <b-form-group id="example-input-group-1" 
                                    v-if="this.form_type == 'pdf'" label="Current PDF file"
                                        label-for="example-input-1">
                                        <a :href="pdf_file_old" target="blank">Click to open in new tab</a>
                                    </b-form-group>

                                   

                                    <!-- <div class="col-md-12 my-4" v-if="this.form_type == 'pdf'">
                                        <div class="file-box row pt-4">
                                            <label>Upload Pdf File</label>
                                            <input @change="onPdfChange" class="form-control form-control"
                                                id="formFileLg" type="file">
                                            <p class="err" v-if="pdfFile_err != ''">{{ pdfFile_err }}</p>
                                        </div>
                                    </div> -->

                                    <div class="row">
                                        <div class="col-md-12 mt-1" v-if="this.form_type == 'pdf'">
                                            <b-form-group id="example-input-group-1" label="Upload Pdf File"
                                                v-if="this.form_type != 'text'" label-for="example-input-1">
                                                <div class="mb-4">
                                                    <input @change="onPdfChange" class="form-control form-control"
                                                        id="formFileLg" type="file">
                                                    <p class="err" v-if="pdfFile_err != ''">{{ pdfFile_err }}</p>
                                                </div>
                                            </b-form-group>
                                        </div>
                                    </div>


                                    <!-- <b-form-group v-if="this.form_type == 'text'" id="example-input-group-1"
                                        label="Text" label-for="example-input-1">
                                        <vue-editor v-model="description" />
                                        <p class="err" v-if="desc_err != ''">{{ desc_err }}</p>
                                    </b-form-group> -->


                                    <b-form-group v-if="this.form_type == 'text'" id="example-input-group-1" label="Text" label-for="contentTextarea">
                                        <b-form-textarea @keydown.native="test_keydown_handler2" id="contentTextarea"
                                            name="contentTextarea" v-model="description"
                                            aria-describedby="input-1-live-feedback" placeholder="Enter something..."
                                            rows="3" max-rows="6"></b-form-textarea>
                                        <p class="err" v-if="desc_err != ''">{{ desc_err }}</p>
                                    </b-form-group>


                                    <div class="container-fluid my-4" v-if="form_type == 'video'">
                                        <!-- for video  -->
                                                
                                        <div class="file-box row"  style="padding-bottom: 30px !important">
                                            <div class="col-md-12">
                                                <div class="row file-text-box" style="margin-left:0px !important;">
                                                    <div class="col-md-6">
                                                        <uploadVideos :fileType="['mp4']" @success="uploadSuccess"
                                                            file_type_url="lesson-content/videos" />
                                                    </div>
                                                    <div class="col-md-6">
                                                        <VideoCompo v-if="this.video_file_path_cloud" :key="video_key"
                                                            :videopath="this.video_file_path_cloud" />
                                                        <VideoCompo v-if="this.video_file_path_editpage" :key="video_key"
                                                            :videopath="this.video_file_path_editpage" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <p class="err" v-if="audioFile_err != ''">{{ audioFile_err }}
                                        </p>

                                    </div>

                                    <b-form-group style="display:none;" class=" mt-4" id="example-input-group-1" label="Select Module"
                                        label-for="example-input-1">
                                        <b-form-select @change="chk_module" class="mb-4" v-model="selected_module"
                                            value-field="id" text-field="title" label="Select Module">
                                            <option :value="null" disabled>-- Please select module --</option>
                                            <!-- <option value="all">All Categories</option> -->
                                            <option v-for="option in all_modules" :value="option.id">
                                                {{ option.title }}
                                            </option>
                                        </b-form-select>
                                        <p class="err" v-if="module_error != ''">{{ module_error }}</p>
                                    </b-form-group>


                                    <!-- <Tag @updateTag="updateTagFromChild" oldtags="" />
                                    <p class="err" v-if="tags_err != ''">{{ tags_err }}</p> -->


                                    <!-- <b-tabs>
                                        <b-tab title="Goals" active>
                                            <Goal @updateGoal="updateGoalFromChild" oldgoals="" />
                                        </b-tab>
                                        <b-tab title="Feelings">
                                            <Feeling @updateFeeling="updateFeelingFromChild" oldfeelings="" />
                                        </b-tab>
                                    </b-tabs>

                                    <p class="err" v-if="goal_err != ''">{{ goal_err }}</p>
                                    <p class="err" v-if="feeling_err != ''">{{ feeling_err }}</p> -->

                                    <!-- v-if="this.form_type != 'text' && this.form_type != 'pdf'"  -->
                                    <b-form-group id="example-input-group-1" label="Description (Optional)"
                                        v-if="this.form_type == 'video' || this.form_type == 'audio'"
                                        label-for="example-input-1">
                                        <b-form-textarea @keydown.native="test_keydown_handler"
                                                 id="descriptionTextarea" name="example-input-1"
                                            v-model="description" aria-describedby="input-1-live-feedback"
                                            placeholder="Enter something..." rows="3" max-rows="6"></b-form-textarea>
                                        <!-- <p class="err" v-if="desc_err != ''">{{ desc_err }}</p> -->
                                    </b-form-group>

                                    <!-- :disabled='is_loading' -->
                                    <!-- v-on:click="submitFunctions" -->

                                    <!-- :disabled="!ready_to_submit" -->
                                    <b-button v-if="form_type == 'video'" type="submit" variant="success"
                                         class="btn-block believe-btn">
                                        <i
                                            :class="is_loading ? 'circle-loader' : 'mdi mdi-file-check btn-icon-prepend'"></i>
                                        Submit
                                    </b-button>

                                    <b-button v-else type="submit" variant="success" class="btn-block believe-btn">
                                        <i
                                            :class="is_loading ? 'circle-loader' : 'mdi mdi-file-check btn-icon-prepend'"></i>
                                        Submit
                                    </b-button>
                                </b-form>

                                <br />
                                <div v-show="is_progress_show">
                                    <progress-bar :value="progress" :options="options" />
                                </div>

                                <vue-snotify></vue-snotify>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { json } from 'body-parser';
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import api from "../../../config/api.js";
// import Tag from "@/components/Tag.vue";
// import Goal from "@/components/Goal.vue";
// import Feeling from "@/components/Feeling.vue";
import AudioFile from "@/components/AudioFile.vue";
import uploadVideos from "@/components/UploadVideos.vue";
import VideoCompo from "./VideoCompo.vue";
import { VueEditor } from "vue2-editor";
// import simpleSnotifysuccess from '@/components/alerts/snotify/simpleSnotifysuccess'
export default {
    name: 'AudioAddModal',
    mixins: [validationMixin],
    data() {
        return {
            module_name : null,
            module_id : null,
            class_val: "col-md-6",
            videoFile_err: "",
            video_file_path: null,
            video_file_path_cloud : null,
            video_file_path_editpage: null,
            video_thumbnail: null,
            ready_to_submit: false,
            video_key: 0,
            form_type: null,
            data_length: 0,
            selected_module: null,
            all_modules: [],
            module_error: "",
            count: 0,
            msg: "",
            is_loading: false,

            pdf_file: "",
            pdf_file_old: "",
            pdfFile_err: "",

            artist_err: "",
            author_err: "",

            name_err: "",
            desc_err: "",

            file_duration_err: "",

            audio_image_url_err: "",
            audio_cover_image_url_err: "",

            audio_image: "",
            audio_image_url: "",
            audio_image2: "",
            audio_image_url2: "",

            audioFile: "",
            audioFile_err: "",
            tags_err: "",
            file_duration: "",
            tags: [],
            goal_err: "",
            feeling_err: "",
            goals: [],
            feelings: [],
                id : "",
                name: "",
                description: "",
                cat_id: "",
                duration: "",
                cover_image: "",
                image: "",
                artist: "",
                author: "",
                is_progress_show: false,
            progress: 0,
            options: {
                text: {
                    color: '#FFFFFF',
                    shadowEnable: true,
                    shadowColor: '#000000',
                    fontSize: 14,
                    fontFamily: 'Helvetica',
                    dynamicPosition: false,
                    hideText: false
                },
                progress: {
                    color: '#2dbd2d',
                    backgroundColor: '#333333',
                    inverted: false
                },
                layout: {
                    height: 35,
                    width: 887,
                    verticalTextAlign: 61,
                    horizontalTextAlign: 50,
                    zeroOffset: 0,
                    strokeWidth: 30,
                    progressPadding: 0,
                    type: 'line'
                }
            }
        }
    },

    components: {
        // Tag,
        AudioFile,
        uploadVideos,
        VideoCompo,
        VueEditor,
        // Goal,
        // Feeling,
        // simpleSnotifysuccess
    },
    validations: {
        form: {
            // name: {
            //     required,
            // },
            // description: {
            //     required,
            // },
            // artist: {
            //     required,
            // },
        }
    },
    watch: {
        name(newVal){
            if (newVal != "" && newVal.length <= 100) {
                this.name_err = "";
            }
        },
        description(newVal) {
            if (newVal != "") {
                this.desc_err = "";
            }
        },
        artist(newVal) {
            if (newVal != "" && newVal.length <= 30) {
                this.artist_err = "";
            }
        },
        author(newVal) {
            if (newVal != "" && newVal.length <= 30) {
                this.author_err = "";
            }
        },
        file_duration(newVal) {
            if (newVal != "") {
                this.file_duration_err = "";
            }
        },
        selected_module(newVal) {
            if (newVal != "") {
                this.module_error = "";
            }
        },
        // tags(newVal) {
        //     if (newVal.length > 0) {
        //         this.tags_err = "";
        //     }
        // },
        // goals(newVal) {
        //     if (newVal.length > 0) {
        //         this.goal_err = "";
        //     }
        // },
        // feelings(newVal) {
        //     if (newVal.length > 0) {
        //         this.feeling_err = "";
        //     }
        // }
    },

    mounted() {
        window.addEventListener("keypress", function (e) {
            if (e.code == "Enter") {
                e.preventDefault()
            }
        }.bind(this));
        this.FetchAllModules();
        this.items();
    },

    methods: {

        test_keydown_handler(event) {
            if (event.which === 13) {
                let txt = document.getElementById('descriptionTextarea');
                let indexOfChar = txt.value.slice(0, txt.selectionStart).length + 1
                txt.value = txt.value.slice(0, txt.selectionStart) + '\r\n' +
                txt.value.slice(txt.selectionStart, txt.value.length);
                this.setCaretPosition('descriptionTextarea',indexOfChar);
            }
        },

        test_keydown_handler2(event) {
            if (event.which === 13) {
                let txt = document.getElementById('contentTextarea');
                let indexOfChar = txt.value.slice(0, txt.selectionStart).length + 1
                txt.value = txt.value.slice(0, txt.selectionStart) + '\r\n' +
                txt.value.slice(txt.selectionStart, txt.value.length);
                this.setCaretPosition('contentTextarea',indexOfChar);
            }
        },

        setCaretPosition(elemId, caretPos) {
            var elem = document.getElementById(elemId);
            if (elem != null) {
                if (elem.createTextRange) {
                    var range = elem.createTextRange();
                    range.move('character', caretPos);
                    range.select();
                }
                else {
                    if (elem.selectionStart) {
                        elem.focus();
                        elem.setSelectionRange(caretPos, caretPos);
                    }
                    else
                        elem.focus();
                }
            }
        },

        // updateTagFromChild(arr) {
        //     this.tags = arr;
        // },

        // updateGoalFromChild(arr) {
        //     this.goals = arr;
        // },
        // updateFeelingFromChild(arr) {
        //     this.feelings = arr;
        // },

        async items() {
            // id, name, author, description, script_id, text, image, tag
            let data = await this.$store.getters['getEditFormData'];
            if (data.length == 0) {
                data = localStorage.getItem('data_for_edit');
                data = JSON.parse(data);
            }
            // id, name, author, description, url, image, tag
            // console.log('mounted from parent');
            // console.log('title : ',data?.title);
            // console.log('desc : ', data?.description);
            // console.log('duration : ', data?.duration);
            // console.log('image : ', data?.image);
            // console.log('cover_image : ', data?.cover_image);
            // console.log('author : ', data?.artist);
            // console.log('tag : ', data?.tag);
            // console.log('id : ', data?.id);
            // console.log('mid : ', data?.module_id);
            // console.log('dl : ', data?.data_length);
            // console.log('type : ', data?.form_type);

            this.name = data?.title;
            if(data?.description != null){
                this.description = data?.description.replaceAll('<br />','</p><p>');
            }
            this.url = data?.url;
            this.file_duration = data?.duration;
            this.audio_image_url = data?.image;
            this.audio_image_url2 = data?.form_type == "pdf" ? data?.image : data?.cover_image;
            this.author = data?.artist;
            this.artist = data?.artist;
            // this.tags = data?.tag;
            // this.goals = data?.goal;
            // this.feelings = data?.feeling;
            this.id = data?.id;
            this.audioFile = data?.url;
            this.video_file_path_editpage = data?.url;
            this.pdf_file = data?.url;
            this.pdf_file_old = data?.url;
            

            this.selected_module = data?.module_id;
            this.data_length = data?.data_length;

            this.module_id = data?.module_id

            this.form_type = data?.form_type
            if (data?.form_type == 'audio') {
                this.class_val = 'col-md-4'
            }
            else {
                this.class_val = 'col-md-6'
            }
            this.module_name = data?.module_name;
        },

        onSubmit() {
            this.$v.form.$touch()
            if (this.$v.form.$anyError) {
                return false;
            }

            if (this.form_type == "video") {
                this.addVideo();
            }
            if (this.form_type == "audio") {
                this.addAudio();
            }
            if (this.form_type == "pdf") {
                this.addPdf();
            }
            if (this.form_type == "text") {
                this.addText();
            }
        },

        // showAddModal(type) {
        //     this.form_type = type;
        //     if (type == 'audio') {
        //         this.video_file_path = null,
        //             this.audioFile_err = "",
        //             this.video_thumbnail = null,
        //             this.ready_to_submit = false,
        //             this.class_val = 'col-md-4'
        //     }
        //     else if (type == 'video') {
        //         this.audioFile_err = "",
        //             this.audioFile = "",
        //             this.class_val = 'col-md-6'
        //     }
        // },

        chk_module() {    
            
            // console.log('errors:::::::');
            //             console.log(this.name_err);
            //             console.log(this.name);
            //             console.log(this.desc_err);
            //             console.log(this.description);
            //             console.log(this.author_err);
            //             console.log(this.author);
            if (this.selected_module != "") {
                this.module_error == "";
            }
        },

        uploadSuccess(data) {
            this.file_duration_db = data?.duration ?? "0";
            this.video_file_path = data.path ?? null;
            this.video_file_path_cloud = data.path2 ?? null;
            this.video_file_path_editpage = null;
            this.video_thumbnail = data.thumbnail ?? null;
            this.video_key++;
            this.audioFile_err = "";
            // console.log('success');
            // console.log(this.video_file_path);
            this.ready_to_submit = true;
        },

        async FetchAllModules() {
            try {
                let result = await api.get(`/admin/all-lessons`);
                // console.log("fetch all courses 1010101");
                // console.log(result);
                // console.log(result.data.all_course_categories);
                this.all_modules = result.data.all_lessons;
                // var obj = {"id" : "all","title" : "all"}
                // this.all_categories.unshift(obj);
            } catch (error) {
                this.error = error.response.data.message;
                this.$toast.error(this.error);
            }
        },

        closeAudioPlayer() {
            this.audioFile = "";
            this.$refs.audioFile.value = null;
        },

        goBack() {
            let prev_url = `/content/list/${this.module_id}`;
            this.$router.push({ path: prev_url });
        },

        // updateTagFromChild(arr) {
        //     this.tags = arr;
        // },
        onAudioImageChange(e) {
            this.audio_image_url_err = "";
            const image = e.target.files[0];
            var status = this.checkFileType(image, "image1");
            if (status) {
                this.audio_image_url_err = "";
                this.audio_image = image;
                this.audio_image_url = URL.createObjectURL(image)
                // const reader = new FileReader();
                // reader.readAsDataURL(image);
                // reader.onload = (e) => {
                //     this.audio_image_url = e.target.result;
                // };
            }
        },

        // onAudioImageChange(e) {
        //     this.form.image = "";
        //     const file = e.target.files[0];
        //     var status = this.checkFileType(file, 'image1')
        //     if (status) {
        //         this.image_error = "";
        //         this.form.image = file;
        //         this.image_url = URL.createObjectURL(file)
        //     }
        // },


        onAudioImageChange2(e) {
            this.audio_cover_image_url_err = "";
            const image = e.target.files[0];
            var status = this.checkFileType(image, "image2");
            if (status) {
                this.audio_cover_image_url_err = "";
                this.audio_image2 = image;
                this.audio_image_url2 = URL.createObjectURL(image)
                // const reader = new FileReader();
                // reader.readAsDataURL(image);
                // reader.onload = (e) => {
                //     this.audio_image_url2 = e.target.result;
                // };
            }
        },

        onPdfChange(e) {
            console.log('on pdf change run');
            this.pdfFile_err = "";
            const file = e.target.files[0];
            var status = this.checkFileType(file, "pdf");
            if (status) {
                this.pdf_file = file;
            }
        },


        checkFileType(file, num) {
            if (num == "video") {
                var types = [
                    "video/mp4",
                    "video/3gp",
                    "video/mov",
                    "video/avi",
                    "video/mkv",
                ];
                if (!types.includes(file.type)) {
                    this.videoFile_err =
                        "The video must be a file of type: mp4, mov, avi";
                    this.videoFile = "";
                    return false;
                }
                return true;
            } else if (num == "audio") {
                types = [
                    "audio/mpeg",
                    "audio/ogg",
                    "audio/wav",
                    "audio/mp4",
                    "audio/mp3",
                ];
                if (!types.includes(file.type)) {
                    this.audioFile_err =
                        "The audio must be a file of type: mp3, wav, ogg";
                    this.audioFile = "";
                    return false;
                }
                return true;
            } else if (num == "pdf") {
                types = ["application/pdf"];
                if (!types.includes(file.type)) {
                    this.pdfFile_err = "The file must be a file of type: pdf";
                    this.pdf_file = "";
                    return false;
                }
                return true;
            } else {
                types = ["image/jpeg", "image/png", "image/jpg"];
                var msg = "The image must be a file of type: jpeg, jpg, png";
                if (!types.includes(file.type)) {
                    if (num == "image1") {
                        this.audio_image_url_err = msg;
                        this.audio_image = "";
                        // this.audio_image_url = "";
                    } else if (num == "image2") {
                        this.audio_cover_image_url_err = msg;
                        this.audio_cover_image = "";
                        // this.audio_image_url2 = "";
                    }
                    return false;
                }
                return true;
            }
        },


        uploadAudio(e) {
            this.audioFile_err = "";
            this.audioFile = "";
            const audio = e.target.files[0];
            var status = this.checkFileType(audio, 'audio');
            if (status) {
                const reader = new FileReader();
                reader.readAsDataURL(audio);
                var audio_el = document.createElement("audio");
                reader.onload = (e) => {
                    this.audioFile = e.target.result;
                    console.log('audio upload');
                    console.log(this.audioFile);
                    audio_el.src = e.target.result;
                    audio_el.addEventListener("loadedmetadata", () => {
                        var duration = audio_el.duration;
                        // const audio_file = new Date(duration * 1000).toISOString().slice(14, 19);
                        this.file_duration_db = duration;
                        // console.log(
                        //   "The duration of the song is of: " + this.file_duration + " seconds"
                        // );
                    });
                };
                this.count++;
                // console.log(this.file_duration, "Song Duration");
            }
            console.log('audio upload');
            console.log(this.audioFile);
        },




        async addText() {
            if(this.name.length > 100 || this.author.length > 30){
                if(this.name.length > 100){
                    this.name_err = "Maximum limit 100 characters";
                }
                if(this.author.length > 30){
                    this.author_err = "Maximum limit 30 characters";
                }
                return false;
            }

            // console.log(this.author);
            if (
                this.selected_module != null
                && this.selected_module != "all" &&
                this.name != "" &&
                this.description != "" &&
                this.author != ""
                                // this.tags.length != 0 &&
                // this.file_duration != ""
                // && this.goals.length != 0
                // && this.feelings.length != 0
            ) {
                try {

                    const formData = new FormData();

                    formData.append("id", this.id);
                    formData.append("title", this.name);
                    formData.append("lesson_id", this.selected_module);
                    if(this.description != null && this.description != ""){
                        const replaceText = this.description.replaceAll('</p><p>','<br />');
                        formData.append("description", replaceText);
                    }
                    formData.append("type", this.form_type);
                    formData.append("artist", this.author);
                    formData.append("duration", 0);
                    formData.append("order", this.data_length + 1);


                    // if (typeof this.tags == "string") {
                    //     formData.append("tag[]", this.tags);
                    // } else {
                    //     for (var i = 0; i < this.tags.length; i++) {
                    //         formData.append("tag[]", this.tags[i]);
                    //     }
                    // }          
                    
                
                    let result = await api.post("/admin/update-lesson-content", formData, {
                        onUploadProgress: function (progressEvent) {
                            this.progress = parseInt(
                                Math.round((progressEvent.loaded / progressEvent.total) * 100)
                            );
                        }.bind(this),
                    });


                    const msg = await result.data.message;
                    this.$store.dispatch('setMessage', { msg });
                    // await this.$store.dispatch(`content/all`);
                    let prev_url = `/content/list/${this.selected_module}`;
                    this.$router.push({ path: prev_url });
                    this.is_loading = false;

                } catch (error) {
                    this.progress = 0;
                    let all_errors = error?.response?.data?.errors ?? "";
                    console.log("myerror", all_errors);

                    this.error = error.response.data.message;
                    this.$toast.error(this.error);
                    this.$toast.error(JSON.stringify(all_errors));
                }
            } else {
                this.name_err = this.name == "" && this.name_err == ""
                    ? "Title is required" : this.name_err;
                this.desc_err = this.description == "" && this.desc_err == ""
                    ? "Text is required" : this.desc_err;

                this.module_error = (this.selected_module == "all" && this.module_error == "") ? "Module is required" : this.module_error
                this.module_error = (this.selected_module == null && this.module_error == "") ? "Module is required" : this.module_error

                this.author_err =
                    this.author == "" && this.author_err == ""
                        ? "Author is required"
                        : this.author_err;
                // this.file_duration_err =
                //     this.file_duration == "" && this.file_duration_err == ""
                //         ? "Duration is required"
                //         : this.file_duration_err;
                // this.tags_err =
                //     this.tags.length == 0 && this.tags_err == ""
                //         ? "Tags required"
                //         : this.tags_err;
                        // this.feeling_err = (this.feelings.length == 0 && this.feeling_err == '') ? "Feelings require" : this.feeling_err
                // this.goal_err = (this.goals.length == 0 && this.goal_err == '') ? "Goals require" : this.goal_err
            }
        },



        async addPdf() {

            if(this.name.length > 100 || this.author.length > 30){
                if(this.name.length > 100){
                    this.name_err = "Maximum limit 100 characters";
                }
                if(this.author.length > 30){
                    this.author_err = "Maximum limit 30 characters";
                }
                return false;
            }

            // console.log(this.author);
            if (
                this.selected_module != null
                && this.selected_module != "all" &&
                this.name != "" &&
                // this.description != "" &&
                this.author != "" &&
                // this.tags.length != 0 &&
                // this.pdf_file != "" &&
                // this.file_duration != "" &&
                this.audio_image_url2 != ""
                // && this.goals.length != 0
                // && this.feelings.length != 0
            ) {
                try {


                    const formData = new FormData();

                    formData.append("id", this.id);
                    formData.append("title", this.name);
                    formData.append("lesson_id", this.selected_module);
                    // if(this.description != null && this.description != ""){
                        formData.append("description", "");
                    // }
                    formData.append("type", this.form_type);
                    formData.append("artist", this.author);
                    formData.append("duration", 0);
                    formData.append("order", this.data_length + 1);


                    // if (typeof this.tags == "string") {
                    //     formData.append("tag[]", this.tags);
                    // } else {
                    //     for (var i = 0; i < this.tags.length; i++) {
                    //         formData.append("tag[]", this.tags[i]);
                    //     }
                    // }

                    if (this.$refs?.audio_image2?.files[0]) {
                        formData.append("cover_image", this.$refs.audio_image2.files[0]);
                    }

                    if (this.pdf_file == "" || this.pdf_file == null) {
                        formData.append("pdf_file", this.pdf_file_old);
                    }else{
                        formData.append("pdf_file", this.pdf_file);
                    }

                    let result = await api.post("/admin/update-lesson-content", formData, {
                        onUploadProgress: function (progressEvent) {
                            this.progress = parseInt(
                                Math.round((progressEvent.loaded / progressEvent.total) * 100)
                            );
                        }.bind(this),
                    });


                    const msg = await result.data.message;
                    this.$store.dispatch('setMessage', { msg });
                    await this.$store.dispatch(`content/all`,{
                        id : this.selected_module
                    });
                    let prev_url = `/content/list/${this.selected_module}`;
                    this.$router.push({ path: prev_url });
                    this.is_loading = false;

                } catch (error) {
                    this.progress = 0;
                    let all_errors = error?.response?.data?.errors ?? "";
                    console.log("myerror", all_errors);

                    this.error = error.response.data.message;
                    this.$toast.error(this.error);
                    this.$toast.error(JSON.stringify(all_errors));
                }
            } else {
                this.name_err = this.name == "" && this.name_err == ""
                    ? "title is required" : this.name_err;
                // this.desc_err = this.description == "" && this.desc_err == ""
                //     ? "Description is required" : this.desc_err;

                this.module_error = (this.selected_module == "all" && this.module_error == "") ? "Module is required" : this.module_error
                this.module_error = (this.selected_module == null && this.module_error == "") ? "Module is required" : this.module_error
                this.pdfFile_err =
                    this.pdf_file == "" && this.pdfFile_err == ""
                        ? "Pdf file is required"
                        : this.pdfFile_err;
                this.author_err =
                    this.author == "" && this.author_err == ""
                        ? "Author is required"
                        : this.author_err;
                // this.file_duration_err =
                //     this.file_duration == "" && this.file_duration_err == ""
                //         ? "Duration is required"
                //         : this.file_duration_err;
                        this.audio_image_url2 == "" && this.audio_cover_image_url_err == ""
                        ? "Image is required"
                        : this.audio_cover_image_url_err;
                // this.tags_err =
                //     this.tags.length == 0 && this.tags_err == ""
                //         ? "Tags required"
                //         : this.tags_err;
                        // this.feeling_err = (this.feelings.length == 0 && this.feeling_err == '') ? "Feelings require" : this.feeling_err
                // this.goal_err = (this.goals.length == 0 && this.goal_err == '') ? "Goals require" : this.goal_err
            }
        },





        async addAudio() {
            if(this.name.length > 100 || this.artist.length > 30){
                if(this.name.length > 100){
                    this.name_err = "Maximum limit 100 characters";
                }
                if(this.artist.length > 30){
                    this.artist_err = "Maximum limit 30 characters";
                }
                return false;
            }
            // console.log(this.artist);
            if (
                this.selected_module != null
                && this.selected_module != "all" &&
                this.name != "" &&
                // this.description != "" &&
                this.artist != "" &&
                this.audioFile != "" &&
                this.audio_image_url2 != ""
                // this.audio_image_url != ""
                // this.tags.length != 0
                // && this.goals.length != 0
                // && this.feelings.length != 0
            ) {
                try {
                    const formData = new FormData();
                    formData.append("id", this.id);
                    formData.append("title", this.name);
                    formData.append("lesson_id", this.selected_module);
                    if(this.description != null && this.description != ""){
                        formData.append("description", this.description);
                    }
                    formData.append("type", this.form_type);
                    // formData.append("artist", this.form_artist);
                    // formData.append("author", this.from_artist);
                    formData.append("artist", this.artist);
                    // formData.append("author", this.artist);
                    formData.append("order", this.data_length + 1);

                    // if (typeof this.tags == "string") {
                    //     formData.append("tag[]", this.tags);
                    // } else {
                    //     for (var i = 0; i < this.tags.length; i++) {
                    //         formData.append("tag[]", this.tags[i]);
                    //     }
                    // }

                    if (this.$refs?.audioFile?.files[0]) {
                        formData.append("audio_file", this.$refs.audioFile.files[0]);
                        formData.append("duration", this.file_duration_db);
                    }

                    if (this.audio_image) {
                        formData.append("image", this.audio_image);
                    }
                    if (this.audio_image2) {
                        formData.append("cover_image", this.audio_image2);
                    }
                    // if (this.$refs?.audio_image?.files[0]) {
                    //     console.log(this.$refs.audio_image.files[0])
                    //     formData.append("image", this.$refs.audio_image.files[0]);
                    // }
                    // if (this.$refs?.audio_image2?.files[0]) {
                    //     console.log(this.$refs.audio_image2.files[0])
                    //     formData.append("cover_image", this.$refs.audio_image2.files[0]);
                    // }
                    this.is_progress_show = true;
                    let result = await api.post("/admin/update-lesson-content", formData, {
                        onUploadProgress: function (progressEvent) {
                            this.progress = parseInt(
                                Math.round((progressEvent.loaded / progressEvent.total) * 100)
                            );
                        }.bind(this),
                    });


                    const msg = await result.data.message;
                    this.$store.dispatch('setMessage', { msg });
                    await this.$store.dispatch(`content/all`,{
                        id : this.selected_module
                    });
                    let prev_url = `/content/list/${this.selected_module}`;
                    this.$router.push({ path: prev_url });
                    this.is_loading = false;

                    //   this.$toast.success(result.data.message);

                    //   this.$root.$refs.Content.callme03();
                    //   const list = document.getElementById("modals-container");
                    //   list.removeChild(list.firstElementChild);

                    //   this.value02 = false;

                    //   this.closebtn();
                    this.is_progress_show = false;
                } catch (error) {
                    this.progress = 0;
                    this.is_progress_show = false;
                    let all_errors = error?.response?.data?.errors ?? "";
                    console.log("myerror", all_errors);

                    this.error = error.response.data.message;
                    this.$toast.error(this.error);
                    this.$toast.error(JSON.stringify(all_errors));
                }
            } else {

                this.name_err = this.name == "" && this.name_err == ""
                    ? "title is required" : this.name_err;
                // this.desc_err = this.description == "" && this.desc_err == ""
                //     ? "Description is required" : this.desc_err;

                this.module_error = (this.selected_module == "all" && this.module_error == "") ? "Module is required" : this.module_error
                this.module_error = (this.selected_module == null && this.module_error == "") ? "Module is required" : this.module_error

                this.artist_err =
                    this.artist == "" && this.artist_err == ""
                        ? "Artist is required"
                        : this.artist_err;

                this.audioFile_err =
                    this.audioFile == "" && this.audioFile_err == ""
                        ? "Audio file is required"
                        : this.audioFile_err;
                // this.tags_err =
                //     this.tags.length == 0 && this.tags_err == ""
                //         ? "Tags required"
                //         : this.tags_err;
                this.audio_image_url_err =
                    this.audio_image_url == "" && this.audio_image_url_err == ""
                        ? "Image is required"
                        : this.audio_image_url_err;
                this.audio_cover_image_url_err =
                    this.audio_image_url2 == "" && this.audio_cover_image_url_err == ""
                        ? "Image is required"
                        : this.audio_cover_image_url_err;
                        // this.feeling_err = (this.feelings.length == 0 && this.feeling_err == '') ? "Feelings require" : this.feeling_err
                // this.goal_err = (this.goals.length == 0 && this.goal_err == '') ? "Goals require" : this.goal_err
            }
        },




        async addVideo() {
            if(this.name.length > 100 || this.artist.length > 30){
                if(this.name.length > 100){
                    this.name_err = "Maximum limit 100 characters";
                }
                if(this.artist.length > 30){
                    this.artist_err = "Maximum limit 30 characters";
                }
                return false;
            }
            // console.log(this.artist);
            if (
                // this.tags.length != 0
                this.selected_module != null
                && this.artist != ""
                && this.selected_module != "all"
                // && this.video_file_path != null
                && this.name != ""
                // this.description != ""
                // && this.goals.length != 0
                // && this.feelings.length != 0
            ) {
                try {
                    this.is_loading = true;
                    // let obj = this.$store.getters['getEditFormData'];
                    // console.log("from add modal obj : :::: ", obj);
                    //     console.log(localStorage.getItem('obj'));
                    // if(!obj){
                    //     obj = localStorage.getItem('obj');                      
                    // }
                    let endpoint = "/admin/update-lesson-content";

                    // if (obj != "") {
                    //     if (obj.type == "hypnosis") {
                    //         endpoint = "/admin/add-hypnosis";
                    //     }
                    //     else if (obj.type == "meditation") {
                    //         endpoint = "/admin/add-meditation";
                    //     }
                    //     else if (obj.type == "affirmation") {
                    //         endpoint = "/admin/add-affirmation";
                    //     }
                    // }

                    // const formData = new FormData();
                    // formData.append("title", this.form?.name);
                    // formData.append("lesson_id", this.selected_module)
                    // formData.append("type", obj.form_type);
                    // formData.append("description", this.description);
                    // formData.append("duration", this.file_duration);
                    // formData.append("artist", this.artist);
                    // formData.append("order", this.data_length + 1);


                    let payload = {
                        id : this.id,
                        title: this.name,
                        lesson_id: this.selected_module,
                        // description: this.description,
                        type: this.form_type,
                        artist: this.artist,
                        // author: this.author,
                        order: this.data_length + 1,
                        // tag: this.tags,
                        // goal: this.goals,
                        // feeling: this.feelings,
                    };

                    if(this.description != null && this.description != ""){
                        payload['description'] = this.description
                    }

                    if (this.video_file_path != "" || this.video_file_path != null) {
                        payload['video_thumbnail'] = this.video_thumbnail;
                        payload['video_file_path'] = this.video_file_path;
                        payload['duration'] = this.file_duration_db;
                    }

                    if (this.audioFile) {
                        payload['audio_file'] = this.audioFile;
                    }

                    if (this.image) {
                        payload['image'] = this.image;
                    }
                    if (this.cover_image) {
                        payload['cover_image'] = this.cover_image
                    }

                    console.log(payload);
                    // if (typeof this.tags == 'string') {
                    //     formData.append('tag[]', this.tags);
                    // } else {
                    //     for (var i = 0; i < this.tags.length; i++) {
                    //         formData.append('tag[]', this.tags[i]);
                    //     }
                    // }


                    let result = await api.post(endpoint, payload);
                    const msg = await result.data.message;
                    this.$store.dispatch('setMessage', { msg });
                    await this.$store.dispatch(`content/all`,{
                        id : this.selected_module
                    });
                    // this.$root.$refs.Audio.$refs['add-modal'].hide();
                    // const category_id = window.location.href.substring(window.location.href.lastIndexOf("/") + 1);
                    let prev_url = `/content/list/${this.selected_module}`;
                    this.$router.push({ path: prev_url });
                    this.is_loading = false;
                } catch (error) {
                    console.log(error);
                    // this.progress = 0;
                    // let err = error.response.data.message;
                    // this.$store.dispatch('setMessage', { err });
                    // this.showSnotifyError()
                    // this.$toast.error(this.error);
                }
            }
            else {
                this.name_err = this.name == "" && this.name_err == ""
                    ? "title is required" : this.name_err;
                // this.desc_err = this.description == "" && this.desc_err == ""
                //     ? "Description is required" : this.desc_err;

                    this.artist == "" && this.artist_err == ""
                        ? "Artist is required"
                        : this.artist_err;

                // this.image_error = (this.image_url == "" && this.image_error == "") ? "Image is required" : this.image_error
                // this.image_error2 = (this.image_url2 == "" && this.image_error2 == "") ? "Image is required" : this.image_error2
                // this.tags_err = (this.tags.length == 0 && this.tags_err == '') ? "Tags required" : this.tags_err
                this.module_error = (this.selected_module == "all" && this.module_error == "") ? "Module is required" : this.module_error
                this.module_error = (this.selected_module == null && this.module_error == "") ? "Module is required" : this.module_error
                // this.feeling_err = (this.feelings.length == 0 && this.feeling_err == '') ? "Feelings require" : this.feeling_err
                // this.goal_err = (this.goals.length == 0 && this.goal_err == '') ? "Goals require" : this.goal_err
            }
        },

    },




}
</script>


<style>
.ql-editor {
    /* background-color: #909090; */
    /* background-color: #166d8c; */
    max-height: 100px;
}
</style>